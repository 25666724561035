import "./global.css";

import LazyLoad from "vanilla-lazyload";

new LazyLoad({
    elements_selector: ".lazyload"
});

const mobileMenuEl = document.querySelector("#header .mobile-menu");
const mobileMainEl = document.querySelector("#header .nav-main");

mobileMenuEl.addEventListener("click", (event) => {
    event.preventDefault();

    mobileMainEl.classList.toggle("is-open");
});