import "./index.css";

const overlayTriggerEls = document.querySelectorAll("[data-overlay-trigger]");

[...overlayTriggerEls].forEach( link => {
	link.addEventListener('click', event => {
		event.preventDefault();
		
		const id = event.currentTarget.getAttribute("data-overlay-id");
		const targetedOverlay = document.querySelector(`.overlay#${id}`);
		
		targetedOverlay.classList.add("is-open");
	})
});

const overlayCloseEls = document.querySelectorAll(".overlay .overlay-close");

[...overlayCloseEls].forEach( link => {
	link.addEventListener('click', event => {
		event.preventDefault();
		
		event.currentTarget.parentNode.parentNode.classList.remove("is-open");
	})
});