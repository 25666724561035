import "./index.css";

import "../../shared/global";
import "../../shared/overlay";
import { vimeoPlayer } from "../../shared/vimeo";

import { tns } from "tiny-slider/src/tiny-slider";

window.addEventListener("DOMContentLoaded", () => {    
    tns({
	    container: ".hero-slider",
	    autoplayButtonOutput: false,
	    nav: false,
	    autoplay: true,
	    touch: false,
	    controls: false,
	    mode: 'gallery',
	    animateDelay: 10000,
	    lazyload: true,
		lazyloadSelector: ".tns-lazy"
	});
	
	const videoTriggerEl = document.querySelector(".intro-body a");
	
	videoTriggerEl.addEventListener("click", event => {
		const vimeoUrl = event.currentTarget.getAttribute("href");
		vimeoPlayer(vimeoUrl, "video");
	});
	
	const videoOverlayCloseEl = document.querySelector("#overlayVideo .overlay-close");
	
	videoOverlayCloseEl.addEventListener("click", event => {
		const overlayEl = event.currentTarget.closest(".overlay");
		const overlayvideoWrapperEl = overlayEl.querySelector(".overlay-body .video-wrapper");
		
		overlayvideoWrapperEl.innerHTML = `<div class="video-player" id="video"></div>`;
	});
});
